import Axios from 'axios';
import { batch } from 'react-redux';

import { ClientActions } from './client';
import { OrderActions } from './order';
import NetworkStatus from '../../utils/enums/NetworkStatus';
import { PaymentCodes } from '../../utils/enums/PaymentCodes';
import { CheckoutFields } from '../reducers/order';
import { TAction } from '../store';

export const Types = {
    SET_PAYMENTS: 'ORDER@SET:PAYMENTS',
    SET_SELECTED_PAYMENT: 'ORDER@SET:SELECTED:PAYMENT',
    SET_PAYMENT_URL: 'ORDER@SET:PAYMENT:URL',
    SET_CLIENT_CARDS: 'ORDER@SET:CLIENT:CARDS',
    SET_CLIENT_SBPS: 'ORDER@SET:CLIENT:SBPS',
};

export type TSetPayments = {
    type: typeof Types.SET_PAYMENTS;
    payload: TPayment[];
};

export type TSetPaymentUrl = {
    type: typeof Types.SET_PAYMENT_URL;
    payload: string | null;
};

export type TSetSelectedPayment = {
    type: typeof Types.SET_SELECTED_PAYMENT;
    payload: TPayment;
};

export type TSetClientCards = {
    type: typeof Types.SET_CLIENT_CARDS;
    payload: TClientCard[];
};

export type TSetClientSbps = {
    type: typeof Types.SET_CLIENT_CARDS;
    payload: TClientSbp[];
};

export type TPaymentActions = TSetPayments;

type PaymentActionsType = {
    fetchPayments: () => TAction<Promise<void>>;
    fetchClientCards: () => TAction<Promise<void>>;
    fetchClientSbps: () => TAction<Promise<void>>;
    deleteClientPayment: (
        uri: string,
        isSbp?: boolean,
    ) => TAction<Promise<void | TSetClientCards | TSetClientSbps>>;
    fetchAnonPayments: () => TAction<Promise<void>>;
    assignPayment: (payment: TPayment) => TAction<Promise<void>>;
    setPayments: (payment: TPayment[]) => TSetPayments;
    setSelectedPayment: (payment: TPayment) => TSetSelectedPayment;
    setPaymentUrl: (payment: string | null) => TSetPaymentUrl;
    setClientCards: (cards: TClientCard[]) => TSetClientCards;
    setClientSbps: (sbps: TClientSbp[]) => TSetClientSbps;
};

type TCommonData = {
    '@id': string;
    '@type': string;
};

export type TPayment = TCommonData & {
    gatewayName: string;
    enabled: boolean;
    notifyUrl: string | null;
    comment: string | null;
    position: number;
    description: string;
    setup: TCommonData & {
        name: string;
        code: PaymentCodes;
        description: string;
        legacy: boolean;
        sbp?: boolean;
    };
};

export type TPaymentAssign = TCommonData & {
    description: number;
    monolithType: number;
    paymentMethod: number;
};

export type TClientCard = TCommonData & {
    userId: string;
    cardMask: string;
    cardHolder: string;
    deviceId: string;
};

export type TClientSbp = TCommonData & {
    userId: string;
    name: string;
    deviceId: string;
};

type TClientCardsResponse = TCommonData & {
    '@context': string;
    'hydra:member': TClientCard[];
    'hydra:totalItems': number;
};

type TClientSbpsResponse = TCommonData & {
    '@context': string;
    'hydra:member': TClientSbp[];
    'hydra:totalItems': number;
};

const paymentCancelToken = Axios.CancelToken.source();

const deviceInfoHeader = {
    'device-info': JSON.stringify({
        uniqueDeviceId: localStorage.getItem('device-id'),
    }),
};

export const PaymentActions: PaymentActionsType = {
    fetchPayments() {
        return async (dispatch, _, { services, httpClientServices }) => {
            dispatch(OrderActions.setNetworkStatus('payments', NetworkStatus.loading));

            await dispatch(ClientActions.clientEnrichToken());

            return httpClientServices
                .getClient('payment')
                .get(services.payment.getPaymentMethods, {
                    cancelToken: paymentCancelToken.token,
                })
                .then(({ data }) => {
                    batch(() => {
                        dispatch(OrderActions.setNetworkStatus('payments', NetworkStatus.ready));
                        dispatch(this.setPayments(data['hydra:member']));
                    });
                })
                .catch(error => {
                    dispatch(OrderActions.setNetworkStatus('payments', NetworkStatus.ready));

                    if (Axios.isCancel(error)) {
                        return;
                    }
                    throw error;
                });
        };
    },
    fetchAnonPayments() {
        return async (dispatch, getState, { httpClientServices }) => {
            dispatch(OrderActions.setNetworkStatus('payments', NetworkStatus.loading));
            const { selectedAddress } = getState().addresses;

            const legalPersonId = selectedAddress?.legal_person_id;

            if (legalPersonId) {
                const url = `/payment/legal_people/${legalPersonId}/payment_methods`;

                return httpClientServices
                    .getClient('payment')
                    .get(url, {
                        cancelToken: paymentCancelToken.token,
                    })
                    .then(({ data }) => {
                        batch(() => {
                            dispatch(
                                OrderActions.setNetworkStatus('payments', NetworkStatus.ready),
                            );
                            dispatch(this.setPayments(data['hydra:member']));
                        });
                    })
                    .catch(error => {
                        dispatch(OrderActions.setNetworkStatus('payments', NetworkStatus.ready));

                        if (Axios.isCancel(error)) {
                            return;
                        }
                        throw error;
                    });
            }
        };
    },
    fetchClientCards() {
        return async (dispatch, _, { httpClientServices }) => {
            return httpClientServices
                .getClient('payment')
                .get<TClientCardsResponse>('/payment/client_cards', {
                    headers: deviceInfoHeader,
                })
                .then(({ data }) => {
                    dispatch(this.setClientCards(data['hydra:member']));
                })
                .catch(e => {
                    throw new Error(e.message);
                });
        };
    },
    fetchClientSbps() {
        return async (dispatch, _, { httpClientServices }) => {
            return httpClientServices
                .getClient('payment')
                .get<TClientSbpsResponse>('/payment/client_sbps', {
                    headers: deviceInfoHeader,
                })
                .then(({ data }) => {
                    dispatch(this.setClientSbps(data['hydra:member']));
                })
                .catch(e => {
                    throw new Error(e.message);
                });
        };
    },
    deleteClientPayment(uri, isSbp) {
        return async (dispatch, _, { httpClientServices }) => {
            return httpClientServices
                .getClient('payment')
                .delete(uri, {
                    headers: deviceInfoHeader,
                })
                .then(() => dispatch(isSbp ? this.fetchClientSbps() : this.fetchClientCards()))
                .catch(e => {
                    throw new Error(e.message);
                });
        };
    },
    assignPayment(payment) {
        return async (dispatch, getState, { httpClientServices, services }) => {
            const { info } = getState().cart;
            const cart = info?.cart;

            if (cart) {
                const data = {
                    cart,
                    monolithType: +payment.setup.code,
                    paymentMethod: payment['@id'],
                    description: payment.description,
                };

                httpClientServices
                    .getClient('cart')
                    .post(services.cart.assignPayment, data)
                    .then(() => {
                        batch(() => {
                            dispatch(OrderActions.setDefaultField(CheckoutFields.payment, data));
                            dispatch(OrderActions.setReadyField(CheckoutFields.payment, true));
                        });
                    })
                    .catch(() => {
                        dispatch(OrderActions.setReadyField(CheckoutFields.payment, false));
                    });
            }
        };
    },
    setPayments(payment) {
        return {
            type: Types.SET_PAYMENTS,
            payload: payment,
        };
    },
    setSelectedPayment(payment) {
        return {
            type: Types.SET_SELECTED_PAYMENT,
            payload: payment,
        };
    },
    setPaymentUrl(data) {
        return {
            type: Types.SET_PAYMENT_URL,
            payload: data,
        };
    },
    setClientCards(cards) {
        return {
            type: Types.SET_CLIENT_CARDS,
            payload: cards,
        };
    },
    setClientSbps(sbps) {
        return {
            type: Types.SET_CLIENT_SBPS,
            payload: sbps,
        };
    },
};
