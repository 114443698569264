import { DeeplinkTypes } from '../../entities/BannersEntity';
import { TBannersDeeplinkActions, Types } from '../actions/bannersDeeplink';

export type TBannersDeeplinktState = {
    component: DeeplinkTypes | null;
};

const initState: TBannersDeeplinktState = {
    component: null,
};

const bannersAction = (
    state = initState,
    action: TBannersDeeplinkActions,
): TBannersDeeplinktState => {
    let newState;
    switch (action.type) {
        case Types.SET_COMPONENT_SHOW:
            newState = { ...state, component: action.payload };
            break;
    }

    return (newState as any) || state;
};

export default bannersAction;
