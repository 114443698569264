const ordersHistory = {
    title: 'Sifariş tarixçəsi',
    btn: {
        more: 'Daha ətraflı',
        repeat: 'Təkrarlayın',
        toCatalog: 'Kataloqa',
        pay: 'Ödəmək',
    },
    error: 'Təəssüf ki, bu sifarişi təkrarlamaq mümkün deyil',
    noOrders: 'Hələ heç bir sifariş verməmisiniz :(',
    cancelOrder: {
        title: 'Sifarişin ləğvi',
        details: {
            orderNumber: 'Sifariş №',
            shippingAddress: 'Çatdırılma ünvanı',
            shippingDate: 'Çatdırılma tarixi və vaxtı',
        },
        leaveComment: 'Şərhinizi yazın',
        selectReasonAndNote: 'Sifarişin ləğvinin səbəbini seçin və ya şərh yazın',
        btn: {
            back: 'Geri',
            cancel: 'Sifarişi ləğv edin',
        },
    },
    orderStatus: {
        new: 'Yeni',
        completed: 'Tamamlandı',
        approved: 'Təsdiqləndi',
        cancelled: 'Ləğv edildi',
        inProcess: 'Işə götürüldü',
        undelivered: 'Çatdırılmadı',
        requiresConfirmation: 'Təsdiqdə',
    },
};

export default ordersHistory;
