const banners = {
    type: {
        clickBanner: 'Рекламный баннер',
        infoBanner: 'Инфо баннер',
        storyBanner: 'История',
        productSetBanner: 'Набор продуктов',
    },
    preview: 'Превью',
    makeOrder: 'Оформить заказ',
};

export default banners;
