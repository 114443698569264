import { message } from 'antd';
import Axios from 'axios';
import { batch } from 'react-redux';

import { TBanners } from '../../entities/BannersEntity';
import { TCompany } from '../../entities/CompanyEntity';
import NetworkStatus from '../../utils/enums/NetworkStatus';
import { TAction } from '../store';

export const Types = {
    SET_COMPANY_INFO: 'COMPANY@SET:INFO',
    SET_COMPANY_LOGO: 'COMPANY@SET:LOGO',
    SET_COMPANY_MOCKUP: 'COMPANY@SET:MOCKUP',
    SET_COMPANY_BANNERS: 'COMPANY@SET:BANNERS',
    SET_NETWORK_STATUS: 'COMPANY@SET:NETWORK:STATUS',
    SET_BANNERS_NETWORK_STATUS: 'COMPANY@SET:BANNERS:NETWORK:STATUS',
};

export type TSetCompanyInfo = {
    type: typeof Types.SET_COMPANY_INFO;
    payload: TCompany;
};

export type TSetCompanyLogo = {
    type: typeof Types.SET_COMPANY_LOGO;
    payload: string;
};

export type TSetCompanyMockup = {
    type: typeof Types.SET_COMPANY_MOCKUP;
    payload: string;
};

export type TSetCompanyBanners = {
    type: typeof Types.SET_COMPANY_BANNERS;
    payload: TBanners[];
};

export type TSetNetworkStatus = {
    type: typeof Types.SET_NETWORK_STATUS;
    payload: NetworkStatus;
};

export type TSetBannersNetworkStatus = {
    type: typeof Types.SET_BANNERS_NETWORK_STATUS;
    payload: NetworkStatus;
};

export type TCompanies = TSetCompanyInfo;

type TCompaniesType = {
    fetchCompanyInfo: () => TAction<Promise<void>>;
    setCompanyInfo: (data: TCompany) => TSetCompanyInfo;
    fetchCompanyLogo: () => TAction<Promise<void>>;
    setCompanyLogo: (url: string) => TSetCompanyLogo;
    fetchCompanyMockup: () => TAction<Promise<void>>;
    setCompanyMockup: (url: string) => TSetCompanyMockup;
    fetchCompanyBanners: () => TAction<Promise<void>>;
    setCompanyBanners: (data: TBanners[]) => TSetCompanyBanners;
    setNetworkStatus: (status: NetworkStatus) => TSetNetworkStatus;
    setBannersNetworkStatus: (status: NetworkStatus) => TSetBannersNetworkStatus;
};

const errorHandler = (error: any, defaultErrorMessage: string) => {
    if (Axios.isCancel(error)) {
        return;
    }
    if (error.response && error.response.status !== 404) {
        const errorMessage = error?.message
            ? `${defaultErrorMessage}. ${error?.message}`
            : defaultErrorMessage;
        message.error(errorMessage);
    }
};

export const CompanyActions: TCompaniesType = {
    fetchCompanyInfo() {
        return async (dispatch, _, { httpClientServices, api }) => {
            dispatch(this.setNetworkStatus(NetworkStatus.loading));
            await httpClientServices
                .getClient()
                .get<TCompany>(api.about)
                .then(({ data }) => {
                    return batch(() => {
                        localStorage.setItem('partnerId', data.partnerId);
                        dispatch(this.setCompanyInfo(data));
                    });
                })
                .catch(e => errorHandler(e, 'Failed to fetch company info'))
                .finally(() => dispatch(this.setNetworkStatus(NetworkStatus.ready)));
        };
    },
    fetchCompanyLogo() {
        return async (dispatch, getState, { httpClientServices }) => {
            const { info } = getState().company;
            const url = `/partner/partners/${info?.partnerId}/logo.json`;
            if (info) {
                httpClientServices
                    .getClient('partner')
                    .get(url)
                    .then(({ data }) => dispatch(this.setCompanyLogo(data.contentUrl)))
                    .catch(e => errorHandler(e, 'Failed to fetch company logo'));
            }
        };
    },
    fetchCompanyMockup() {
        return async (dispatch, getState, { httpClientServices }) => {
            const { info } = getState().company;
            const url = `/landing/partners/${info?.partnerId}/landing/application_mockup.json`;
            if (info) {
                httpClientServices
                    .getClient('landing')
                    .get(url)
                    .then(({ data }) => dispatch(this.setCompanyMockup(data.contentUrl)))
                    .catch(e => errorHandler(e, 'Failed to fetch company mockup'));
            }
        };
    },
    fetchCompanyBanners() {
        return async (dispatch, getState, { httpClientServices }) => {
            const { info } = getState().company;
            const { selectedAddress } = getState().addresses;

            const coordinates = localStorage.getItem('geolocationCoordinates')?.split(',');

            let params = {};
            if (selectedAddress) {
                params = {
                    'coordinates[longitude]': selectedAddress.coordinates.longitude,
                    'coordinates[latitude]': selectedAddress.coordinates.latitude,
                };
            } else if (coordinates) {
                params = {
                    'coordinates[latitude]': coordinates[0],
                    'coordinates[longitude]': coordinates[1],
                };
            } else if (info?.defaultCity?.center_latitude && info?.defaultCity?.center_longitude) {
                params = {
                    'coordinates[longitude]': info.defaultCity.center_longitude,
                    'coordinates[latitude]': info.defaultCity.center_latitude,
                };
            }

            if (info) {
                dispatch(this.setBannersNetworkStatus(NetworkStatus.loading));
                httpClientServices
                    .getClient('marketing')
                    .get('/marketing/banners', { params })
                    .then(({ data }) => dispatch(this.setCompanyBanners(data['hydra:member'])))
                    .catch(() => {})
                    .finally(() => {
                        dispatch(this.setBannersNetworkStatus(NetworkStatus.ready));
                    });
            }
        };
    },
    setCompanyInfo(data) {
        return {
            type: Types.SET_COMPANY_INFO,
            payload: data,
        };
    },
    setCompanyLogo(url) {
        return {
            type: Types.SET_COMPANY_LOGO,
            payload: url,
        };
    },
    setCompanyMockup(url) {
        return {
            type: Types.SET_COMPANY_MOCKUP,
            payload: url,
        };
    },
    setCompanyBanners(data) {
        return {
            type: Types.SET_COMPANY_BANNERS,
            payload: data,
        };
    },
    setNetworkStatus(status) {
        return {
            type: Types.SET_NETWORK_STATUS,
            payload: status,
        };
    },
    setBannersNetworkStatus(status) {
        return {
            type: Types.SET_BANNERS_NETWORK_STATUS,
            payload: status,
        };
    },
};
