import banners from './banners';
import cart from './cart';
import cartDrawer from './cartDrawer';
import cartModal from './cartModal';
import catalog from './catalog';
import catalogBottomSection from './catalogBottomSection';
import cookieNotification from './cookieNotification';
import downloadAppRedirect from './downloadAppRedirect';
import errors from './errors';
import layout from './layout';
import login from './login';
import notifications from './notifications';
import ordersHistory from './ordersHistory';
import pointsModal from './pointsModal';
import product from './product';
import profile from './profile';
import staticPages from './staticPages';

const index = {
    cookieNotification,
    downloadAppRedirect,
    cart,
    cartDrawer,
    cartModal,
    catalog,
    catalogBottomSection,
    errors,
    layout,
    login,
    notifications,
    ordersHistory,
    pointsModal,
    product,
    profile,
    staticPages,
    banners,
};

export default index;
