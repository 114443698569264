const profile = {
    title: 'Кабинет',
    myAddress: 'Мои адреса',
    historyOrders: 'История заказов',
    messages: 'Чат с поддержкой',
    bonuses: 'Ваши баллы',
    share: 'Поделиться ссылкой',
    delivery: 'Доставка и оплата',
    aboutCompany: 'О компании',
    downloadApp: 'Скачать приложение',
    signOut: 'Выйти из профиля',
    btnLogoutOk: 'Да, выйти',
    deleteAccount: 'Удалить аккаунт',
    modalDeleteAccount: {
        title: 'Удалить аккаунт?',
        btnDelete: 'Да',
        btnClose: 'Нет',
    },
    messageSignOut: 'При выходе из профиля все товары удалятся из корзины. Выйти из профиля?',
    userPlaceholder: 'Имя пользователя',
    emailPlaceholder: 'email',
    copy: 'Ссылка скопирована',
    history: {
        repeat: 'Повторить заказ',
        more: 'Подробнее',
        order: 'Заказ №',
        cancel: 'Отменить заказ',
        amount: 'Стоимость: ',
        deliveryAmount: 'Доставка: ',
        quantity: 'Количество: ',
    },
};

export default profile;
