import { TransactionState, Debit, TResponseExtensons } from "./types";
import { ErrorResponse } from "apollo-link-error";
import { AxiosError } from "axios";
import { ApolloError } from "apollo-boost";
import dayjs, { Dayjs } from "dayjs";

export const collectionFragment = (fragmentName: string, extantion: string = ''): string => {
    return `
            totalCount,
            edges{
                cursor,
                node{
                    ...${fragmentName}${extantion && ','}
                    ${extantion}
                }
            }
            pageInfo{
              endCursor,
              hasNextPage
            }
        `
};

export const collectionFields = (fields: string): string => {
    return `
            totalCount,
            edges{
                cursor,
                node{
                    ${fields}
                }
            }
            pageInfo{
              endCursor,
              hasNextPage
            }
        `
};

export const parseUuid = (id: string) => {
    return id.split("/").reverse()[0]
}


export interface ConverDateOptions {
    timeFormat?: string
}

export function convertDateToServerFormat(date: Date | Dayjs, { timeFormat }: ConverDateOptions = {}) {
    return dayjs(date).format(`YYYY-MM-DD${timeFormat ? ` ${timeFormat}` : ''}`)
}


export function prefixSum(state: TransactionState) {
    if (state === Debit.confirmed) return '+';
    return '–';
}

export function withCurrency(sum: number, currency: string) {
    return new Intl.NumberFormat('ru', { style: 'currency', currency }).format(sum)
}

export function getMonthlyPayment(amount: number, months: number) {
    return Math.floor(amount / months * 100) / 100;
}

export function currency(currency: string) {
    //@ts-ignore
    const type = new Intl.NumberFormat('ru', { style: 'currency', currency }).formatToParts().find((item) => item.type === "currency");
    return type ? type.value : currency
}

export function lcFirst(str: string) {
    if (!str || str.length === 0) return str
    return str[0].toLowerCase() + str.slice(1)
}


export function validationErrors(e: ErrorResponse): Record<string, string> | undefined {
    if(!e) return undefined;

    if (e.response && e.response.data && e.response.data.violations) {
        const result: Record<string, string> = {}
        e.response.data.violations.forEach((violation: any)=>{
            result[violation.propertyPath] = violation.message
        })
        return result
    }


    if (!e.graphQLErrors) return undefined;
    if (!e.graphQLErrors.length) return undefined;
    if (!e.graphQLErrors[0].extensions) return undefined;

    const extensions: TResponseExtensons = e.graphQLErrors[0].extensions as any;

    if(!extensions.violations) return undefined;
    const result: Record<string, string> = {}
    extensions.violations.forEach(violation=>{
        result[violation.path] = violation.message
    })
    return result
}


export function parseError(e: AxiosError | ApolloError | string): string | undefined {
    if(!e) return undefined;
    if(typeof e === 'string' ) return e;
    //@ts-ignore
    if(e.isAxiosError){
        const axiosError = e as AxiosError
        if (!axiosError.response) return e.message;
        if (!axiosError.response.data) return e.message;
        if (!!axiosError.response.data.message) return axiosError.response.data.message;
        if (!!axiosError.response.data.errors && axiosError.response.data.errors.length ) return axiosError.response.data.errors[0].message;
        if (!axiosError.response.data["hydra:description"]) return e.message;
        return axiosError.response.data["hydra:description"]
    }

    const apolloError = e as ApolloError;

    if (apolloError.graphQLErrors) {
         //@ts-ignore
        return `${apolloError.graphQLErrors[0].message}. ${apolloError.graphQLErrors[0].debugMessage || ""}`
    }

    if (apolloError.networkError) {
        return `${apolloError.networkError.message}. ${apolloError.networkError.name || ""}`
    }

    return e.message
   
}

export const formatWithSign = (temperature: number) => {
    return (temperature < 0 ? "" : "+") + temperature;
}

export const roundNumber = (number: number, precision: number = 2) => {
    const precisionPow = Math.pow(10, precision);

    return Math.floor(number * precisionPow) / precisionPow;
}

export const composeQueries = <T extends string, V>(
    queries: Record<T, V>,
    queryGenerator: (variables: V) => string
): string[] =>
    Object.entries<V>(queries).map(([name, variables]) => {
        return `${name}: ${queryGenerator(variables)}`;
    });