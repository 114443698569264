const catalogBottomSection = {
    title: 'More',
    aboutCompany: {
        title: 'About company',
    },
    deliveryPayment: {
        title: 'Delivery & payment',
        shippingTime: 'Delivery hours:',
        titlePaymentMethods: 'Payment methods:',
        paymentMethods: {
            softInvoice: 'Soft Invoice',
            sberbankRecurring: 'Sberbank recurring',
            yandexKassa: 'Yandex Kassa',
            yooMoneyRecurring: 'Yoomoney recurring',
            offlineAcquiring: 'Bank card',
            cash: 'Cash',
            googlePay: 'Google Pay',
            applePay: 'Apple Pay ',
            sberbank: 'Sberbank',
            loyalty: 'Pay with points',
            contract: 'Under contract',
            eximbank: 'Eximbank',
            sberbankOnlineByPhone: 'SberPay',
            sberbankOnlineByQr: 'SberPay (QR code)',
            liqpay: 'Liqpay',
            tinkoff: 'Tinkoff',
            sberbanksbp: 'Faster Payments System (SBP)',
            contractForLegalPerson: 'Payment by invoice for legal entities',
        },
    },
    downloadAppModal: {
        title: 'Download app',
        text: 'Download the app and place your orders conveniently!',
        textMobile: 'Download the app and place orders conveniently',
        img: 'Mobile app',
    },
};

export default catalogBottomSection;
