import { TBanners } from '../../entities/BannersEntity';
import { TCompany } from '../../entities/CompanyEntity';
import NetworkStatus from '../../utils/enums/NetworkStatus';
import { TCompanies, Types } from '../actions/company';

export declare type TCompanyState = {
    info: TCompany | null;
    logo: string | null;
    mockup: string | null;
    banners: TBanners[] | null;
    networkStatus: NetworkStatus;
    bannersNetworkStatus: NetworkStatus;
};

const initState: TCompanyState = {
    info: null,
    logo: null,
    mockup: null,
    banners: null,
    networkStatus: NetworkStatus.pending,
    bannersNetworkStatus: NetworkStatus.pending,
};

const company = (state = initState, action: TCompanies): TCompanyState => {
    let newState;
    switch (action.type) {
        case Types.SET_COMPANY_INFO:
            newState = { ...state, info: action.payload };
            break;
        case Types.SET_COMPANY_LOGO:
            newState = { ...state, logo: action.payload };
            break;
        case Types.SET_COMPANY_MOCKUP:
            newState = { ...state, mockup: action.payload };
            break;
        case Types.SET_COMPANY_BANNERS:
            newState = { ...state, banners: action.payload };
            break;
        case Types.SET_NETWORK_STATUS:
            newState = { ...state, networkStatus: action.payload };
            break;
        case Types.SET_BANNERS_NETWORK_STATUS:
            newState = { ...state, bannersNetworkStatus: action.payload };
            break;
    }
    return (newState as any) || state;
};

export default company;
