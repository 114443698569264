const ordersHistory = {
    title: 'Order history',
    btn: {
        more: 'More',
        repeat: 'Repeat',
        toCatalog: 'To catalog',
        pay: 'Pay',
    },
    error: 'Unfortunately, this order cannot be repeated',
    noOrders: 'You have not made any orders yet :(',
    cancelOrder: {
        title: 'Cancel order',
        details: {
            orderNumber: 'Order number',
            shippingAddress: 'Shipping address',
            shippingDate: 'Date and time of delivery',
        },
        leaveComment: 'Leave your comment',
        selectReasonAndNote: 'Select cancel reason or leave a comment',
        btn: {
            back: 'Back',
            cancel: 'Cancel order',
        },
    },
    orderStatus: {
        new: 'New',
        completed: 'Completed',
        approved: 'Approved',
        cancelled: 'Cancelled',
        inProcess: 'In process',
        undelivered: 'Undelivered',
        requiresConfirmation: 'On confirmation',
    },
};

export default ordersHistory;
