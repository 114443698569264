const catalog = {
    addressSelector: {
        title: 'Мои адреса',
        addAddress: '+ Добавить адрес',
    },
    enterAddressPanel: {
        title: 'Укажите адрес для актуальных товаров и цен',
        buttonMap: 'Показать на карте',
        buttonNotFoundAddress: 'Не нашёл адрес',
        addAddress: '+ Добавить адрес',
        inputPlaceholder: 'Введите город, улицу и дом',
    },
    confirmDeleteAddress: 'Вы действительно хотите удалить адрес?',
    confirmClearCart:
        'Вы действительно хотите удалить выбранный адрес? Товары из корзины будут удалены.',
};

export default catalog;
