import NetworkStatus from '../../utils/enums/NetworkStatus';
import { TAddressAssign } from '../actions/addresses';
import { TNoteAssign } from '../actions/cart';
import { TPeriod } from '../actions/intervals';
import { Types, TOrderActions, OrderModal, TCancelOrderReasons } from '../actions/order';
import { TClientCard, TPayment, TPaymentAssign, TClientSbp } from '../actions/payment';

declare global {
    interface Window {
        isReadyFields?: OrderState['isReadyFields'];
    }
}

export enum CheckoutFields {
    address = 'AddressField',
    interval = 'IntervalField',
    payment = 'PaymentField',
    note = 'NoteField',
}

export declare type OrderState = {
    intervals: TPeriod[];
    payments: TPayment[];
    selectedPayment: TPayment | null;
    paymentUrl: string | null;
    isReadyFields: {
        [CheckoutFields.address]: boolean;
        [CheckoutFields.interval]: boolean;
        [CheckoutFields.payment]: boolean;
    };
    defaultFields: {
        [CheckoutFields.address]: TAddressAssign | null;
        [CheckoutFields.note]: TNoteAssign | null;
        [CheckoutFields.payment]: TPaymentAssign | null;
    };
    networkItems: Record<OrderNetworkItems, NetworkStatus>;
    promocodeStatus: TPromocodeStatus;
    promocodeError: string | null;
    orderModal: OrderModal | null;
    cancelOrderReasons: TCancelOrderReasons[];
    orderErrorMessage: string | null;
    clientCards: TClientCard[];
    clientSbps: TClientSbp[];
    completeNetworkStatus: NetworkStatus;
};

export enum PromocodeStatus {
    Valid = 'valid',
    Invalid = 'invalid',
}

export type TPromocodeStatus = PromocodeStatus | null;

export type OrderNetworkItems = 'intervals' | 'payments' | 'promocode';

const initState: OrderState = {
    intervals: [],
    payments: [],
    selectedPayment: null,
    paymentUrl: null,
    isReadyFields: {
        AddressField: false,
        IntervalField: false,
        PaymentField: false,
    },
    defaultFields: {
        AddressField: null,
        PaymentField: null,
        NoteField: null,
    },
    networkItems: {
        intervals: NetworkStatus.pending,
        payments: NetworkStatus.pending,
        promocode: NetworkStatus.pending,
    },
    orderModal: null,
    promocodeStatus: null,
    promocodeError: null,
    cancelOrderReasons: [],
    orderErrorMessage: null,
    clientCards: [],
    clientSbps: [],
    completeNetworkStatus: NetworkStatus.pending,
};

const Order = (state = initState, action: TOrderActions): OrderState => {
    window.isReadyFields = state.isReadyFields;
    let newState;
    switch (action.type) {
        case Types.SET_INTERVALS:
            newState = { ...state, intervals: action.payload };
            break;
        case Types.SET_PAYMENTS:
            newState = { ...state, payments: action.payload };
            break;
        case Types.SET_SELECTED_PAYMENT:
            newState = { ...state, selectedPayment: action.payload };
            break;
        case Types.SET_PAYMENT_URL:
            newState = { ...state, paymentUrl: action.payload };
            break;
        case Types.SET_NETWORK_STATUS:
            newState = { ...state, networkItems: action.payload };
            break;
        case Types.SET_READY_FIELD:
            newState = { ...state, isReadyFields: action.payload };
            break;
        case Types.SET_DEFAULT_FIELD:
            newState = { ...state, defaultFields: action.payload };
            break;
        case Types.SET_PROMOCODE_STATUS:
            newState = { ...state, promocodeStatus: action.payload };
            break;
        case Types.SET_PROMOCODE_ERROR:
            newState = { ...state, promocodeError: action.payload };
            break;
        case Types.SET_ORDER_MODAL:
            newState = { ...state, orderModal: action.payload };
            break;
        case Types.SET_CANCEL_ORDER_REASONS:
            newState = { ...state, cancelOrderReasons: action.payload };
            break;
        case Types.SET_ORDER_ERROR_MESSAGE:
            newState = { ...state, orderErrorMessage: action.payload };
            break;
        case Types.SET_CLIENT_CARDS:
            newState = { ...state, clientCards: action.payload };
            break;
        case Types.SET_CLIENT_SBPS:
            newState = { ...state, clientSbps: action.payload };
            break;
        case Types.SET_COMPLETE_NETWORK_STATUS:
            newState = { ...state, completeNetworkStatus: action.payload };
            break;
    }

    return (newState as any) || state;
};

export default Order;
