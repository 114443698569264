import NetworkStatus from '../../utils/enums/NetworkStatus';
import {
    TAquamarketActions,
    TCell,
    TReturnableContainer,
    Types,
    TCalculate,
} from '../actions/aquamarket';

export declare type AquamarketState = {
    cell: TCell | null;
    returnableContainer: TReturnableContainer | null;
    isSbpPayment: boolean;
    calculateResult: TCalculate | null;
    cellNetworkStatus: NetworkStatus;
    calculateNetworkStatus: NetworkStatus;
};

const initState: AquamarketState = {
    cell: null,
    returnableContainer: null,
    isSbpPayment: false,
    calculateResult: null,
    cellNetworkStatus: NetworkStatus.pending,
    calculateNetworkStatus: NetworkStatus.pending,
};

const aquamarket = (state = initState, action: TAquamarketActions): AquamarketState => {
    let newState;
    switch (action.type) {
        case Types.SET_CELL:
            newState = { ...state, cell: action.payload };
            break;
        case Types.SET_RETURNABLE_CONTAINER:
            newState = { ...state, returnableContainer: action.payload };
            break;
        case Types.SET_IS_SBP_PAYMENT:
            newState = { ...state, isSbpPayment: action.payload };
            break;
        case Types.SET_CALCULATE:
            newState = { ...state, calculateResult: action.payload };
            break;
        case Types.SET_CELL_NETWORK_STATUS:
            newState = { ...state, cellNetworkStatus: action.payload };
            break;
        case Types.SET_CALCULATE_NETWORK_STATUS:
            newState = { ...state, calculateNetworkStatus: action.payload };
    }

    return (newState as any) || state;
};

export default aquamarket;
