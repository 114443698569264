import { message } from 'antd';
import Axios from 'axios';

import { TAction } from '../store';

export const Types = {
    SET_BONUS_POINTS_HISTORY: 'BONUS@SET:HISTORY',
    SET_ABOUT_LOYALTY_SYSTEM: 'BONUS@SET:ABOUT:LOYALTY:SYSTEM',
    SET_IS_USING_BONUS_POINTS: 'BONUS@SET:IS:USING:POINTS',
};

export type TBonusPointsHistoryItem = {
    id: number;
    user_id: number;
    order_id: number | null;
    reason_id: number;
    comment: string;
    coin: number;
    created_at: string;
    updated_at: string;
    created_by: any;
};

export type TSetBonusPointsHistoryAction = {
    type: typeof Types.SET_BONUS_POINTS_HISTORY;
    payload: TBonusPointsHistoryItem[];
};

export type TSetAboutLoyaltySystem = {
    type: typeof Types.SET_ABOUT_LOYALTY_SYSTEM;
    payload: string;
};

export type TSetIsUsingBonusPoints = {
    type: typeof Types.SET_IS_USING_BONUS_POINTS;
};

export type BonusPointsAction = TSetBonusPointsHistoryAction;

type BonusPointsActionsType = {
    assignBonusPointsToCart: (paidByPoints: boolean) => TAction<Promise<void>>;
    fetchBonusPointsHistory: () => TAction<Promise<void>>;
    fetchAboutLoyaltySystem: () => TAction<Promise<void>>;
    setBonusPointsHistory: (items: TBonusPointsHistoryItem[]) => TSetBonusPointsHistoryAction;
    setAboutLoyaltySystem: (text: string) => TSetAboutLoyaltySystem;
    setIsUsingBonusPoints: (isUsing: boolean) => TSetIsUsingBonusPoints;
};

export const BonusPointsActions: BonusPointsActionsType = {
    assignBonusPointsToCart(paidByPoints) {
        return async (_, getState, { httpClientServices }) => {
            const { info } = getState().cart;

            if (info?.cart) {
                await httpClientServices
                    .getClient('cart')
                    .post('/order/assign_cart_loyalty_points_events', {
                        cart: info.cart,
                        paidByPoints,
                    })
                    .catch(e => message.error(e.message));
            }
        };
    },
    fetchBonusPointsHistory() {
        return async (dispatch, _, { httpClientServices }) => {
            httpClientServices
                .getClient()
                .get('/v1/loyalty-system/history')
                .then(({ data }) => {
                    if (data.success) {
                        dispatch(this.setBonusPointsHistory(data.data));
                    } else {
                        throw Error('Unable to fetch history');
                    }
                });
        };
    },

    fetchAboutLoyaltySystem() {
        return async (dispatch, getState) => {
            const url = getState().company.info?.about_loyalty_system_url;
            if (url) {
                Axios.get(url).then(({ data }) => {
                    dispatch(this.setAboutLoyaltySystem(data));
                });
            }
        };
    },

    setBonusPointsHistory(items) {
        return {
            type: Types.SET_BONUS_POINTS_HISTORY,
            payload: items,
        };
    },

    setAboutLoyaltySystem(text) {
        return {
            type: Types.SET_ABOUT_LOYALTY_SYSTEM,
            payload: text,
        };
    },
    setIsUsingBonusPoints(isUsing) {
        return {
            type: Types.SET_IS_USING_BONUS_POINTS,
            payload: isUsing,
        };
    },
};
