const pointsModal = {
    title: 'Bonus points',
    rulesTitle: 'Terms of use',
    historyTitle: 'Accrual history',
    login: 'Login or register to earn points',
    btnAuth: 'Вход / Регистрация',
    marketing: {
        title: '1 point = 1 ruble',
        subtitle: 'How to get bonuses?',
        text:
            'Next to the price of each item is the number of points you will receive when you purchase that item. Bonuses are awarded after the successful delivery of the order (for canceled orders and for orders fully or partially paid with points, bonuses are not awarded). How to spend bonuses? Pay for purchases with points when placing an order through the application.',
    },
    download: {
        start: 'Download the app',
        end: 'to use bonuses for payment',
    },
    btnDownload: 'Download app',
};

export default pointsModal;
