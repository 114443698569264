const catalog = {
    addressSelector: {
        title: 'Mənim ünvanlarım',
        addAddress: '+ Ünvan əlavə edin',
    },
    enterAddressPanel: {
        title: 'Cari məhsullar və qiymətlər üçün ünvanı göstərin',
        buttonMap: 'Xəritədə göstər',
        buttonNotFoundAddress: 'Ünvanı tapmadım',
        addAddress: '+ Ünvan əlavə edin',
        inputPlaceholder: 'Şəhəri, küçəni və evi daxil edin',
    },
    confirmDeleteAddress: 'Həqiqətən ünvanı silmək istəyirsiz ?',
    confirmClearCart:
        'Həqiqətən seçilmiş ünvanı silmək istəyirsiniz? Səbətdəki məhsullar silinəcək.',
};

export default catalog;
