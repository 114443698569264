import { getAppConfig } from './appConfig';

export const getYMapsToken = () => {
    const { yandexMapTokens } = getAppConfig();
    if (!yandexMapTokens || !yandexMapTokens?.length) {
        return null;
    }
    const index = Math.floor(Math.random() * yandexMapTokens.length);

    return yandexMapTokens[index];
};
