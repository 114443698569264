const errors = {
    error: 'Ошибка: {{message}}',
    accessError: 'Ошибка доступа. Пожалуйста, выполните вход заново',
    addReturnedBottle: 'Ошибка при добавлении возвратной тары',
    reduceReturnedBottle: 'Ошибка при удалении возвратной тары',
    addressExists: 'Ошибка добавления. Этот адрес уже существует',
    assignInterval: 'Не удалось назначить выбранный интервал',
    assignAddress: 'Не удалось назначить выбранный адрес',
    assignPromocode: 'Не удалось применить промокод',
    fetchProductSets: 'Ошибка при получении наборов товаров',
    reduceProductSet: 'Ошибка при уменьшении количества наборов товаров в корзине',
    addProductSet: 'Ошибка при добавлении набора товаров в корзину',
    changeProductQuantity: 'Ошибка при изменении количества товара в корзине',
    changeProductSetQuantity: 'Ошибка при изменении количества наборов товаров в корзине',
    createAddress: 'Ошибка при создании адреса',
    createAnonAddress: 'Ошибка при создании анонимного адреса',
};

export default errors;
