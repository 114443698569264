import NetworkStatus from '../../utils/enums/NetworkStatus';
import {
    TCartActions,
    TCartCalculate,
    TCartItem,
    TDeletedItem,
    TInitCart,
    Types,
} from '../actions/cart';

export declare type CatalogState = {
    info: TInitCart | null;
    items: Record<number, TCartItem> | null;
    deletedItems: TDeletedItem[];
    calculate: TCartCalculate | null;
    calculateNetworkStatus: NetworkStatus;
    detailNetworkStatus: NetworkStatus;
    initCartNetworkStatus: NetworkStatus;
};

const initState: CatalogState = {
    info: null,
    items: null,
    deletedItems: [],
    calculate: null,
    calculateNetworkStatus: NetworkStatus.pending,
    detailNetworkStatus: NetworkStatus.pending,
    initCartNetworkStatus: NetworkStatus.pending,
};

const Cart = (state = initState, action: TCartActions): CatalogState => {
    let newState;
    switch (action.type) {
        case Types.INIT_CART:
            newState = { ...state, info: action.payload };
            break;
        case Types.SET_PRODUCTS:
            newState = { ...state, items: action.payload };
            break;
        case Types.SET_DELETED_PRODUCTS:
            newState = { ...state, deletedItems: action.payload };
            break;
        case Types.SET_CALCULATE_NETWORK_STATUS:
            newState = { ...state, calculateNetworkStatus: action.payload };
            break;
        case Types.SET_DETAIL_NETWORK_STATUS:
            newState = { ...state, detailNetworkStatus: action.payload };
            break;
        case Types.SET_INIT_CART_NETWORK_STATUS:
            newState = { ...state, initCartNetworkStatus: action.payload };
            break;
        case Types.SET_CALCULATE:
            newState = { ...state, calculate: action.payload };
            break;
    }
    return (newState as any) || state;
};

export default Cart;
