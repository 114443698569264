const product = {
    title: '',
    count: 'Miktar',
    totalPay: ' ödenecek: ',
    addButton: ' Eklemek',
    addedButton: 'Katma',
    price: 'Fiyat: ',
    promotion: "Malların miktarı 1'den büyükse fiyat düşebilir",
    maxCountMessage: 'Mevcut malların maksimum miktarına ulaşıldı',
    ct: 'adet',
};

export default product;
