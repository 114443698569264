const catalogBottomSection = {
    title: 'Etraflıca',
    aboutCompany: {
        title: 'şirket hakkında',
    },
    deliveryPayment: {
        title: 'Teslimat ve ödeme',
        shippingTime: 'Teslimat çalışma şekli:',
        titlePaymentMethods: 'Ödeme yöntemleri:',
        paymentMethods: {
            softInvoice: 'Yumuşak fatura',
            sberbankRecurring: "Sberbank'ın tekrarı",
            yandexKassa: 'Yandex Kassa',
            yooMoneyRecurring: 'Yoomoney tekrar ediyor',
            offlineAcquiring: 'Banka kartı ile',
            cash: 'Mevcutlarla',
            googlePay: 'Google Pay',
            applePay: 'Apple Pay ',
            sberbank: 'Sberbank',
            loyalty: 'Puanlarla ödeme',
            contract: 'sözleşmeye göre ödeme',
            eximbank: 'Eximbank',
            sberbankOnlineByPhone: 'SberPay',
            sberbankOnlineByQr: 'SberPay (QR code)',
            liqpay: 'Liqpay',
            tinkoff: 'Tinkoff',
            sberbanksbp: 'Faster Payments System (SBP)',
            contractForLegalPerson: 'tüzel kişiler için hesapla ödeme',
        },
    },
    downloadAppModal: {
        title: 'Uygulamayı indirin',
        text: 'Uygulamayı indirin ve siparişleri rahatça yapın!',
        textMobile: 'Uygulamayı indirin ve siparişleri rahatça yapın',
        img: 'Mobil uygulama',
    },
};

export default catalogBottomSection;
