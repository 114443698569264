import React from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async';

import { getAppConfig } from '../../utils/appConfig';

const Seo: React.FC = ({ children }) => {
    const { metaTags } = getAppConfig();
    const localUrl = 'https://' + localStorage.getItem('serverUrl');

    return (
        <HelmetProvider>
            {metaTags && (
                <Helmet prioritizeSeoTags>
                    <title>{metaTags.title}</title>
                    <meta property="og:title" content={metaTags.title} />
                    <meta property="og:description" content={metaTags.description} />
                    <meta name="title" content={metaTags.title} />
                    <meta name="description" content={metaTags.description} />
                    <meta name="keywords" content={metaTags.keywords} />
                    <link rel="canonical" href={localUrl} />
                </Helmet>
            )}
            {children}
        </HelmetProvider>
    );
};

export default Seo;
