import { message } from 'antd';
import Axios from 'axios';
import { batch } from 'react-redux';

import { CartActions } from './cart';
import { OrderActions } from './order';
import NetworkStatus from '../../utils/enums/NetworkStatus';
import { CheckoutFields } from '../reducers/order';
import { TAction } from '../store';
import { t } from '../../aqua-delivery-web-client-ui/i18n';
import { translateMap } from '../../translations';

export const Types = {
    SET_INTERVALS: 'ORDER@SET:INTERVALS',
};

export type TSetIntervals = {
    type: typeof Types.SET_INTERVALS;
    payload: TPeriod[];
};

export type TIntervalActions = TSetIntervals;

type IntervalActionsType = {
    fetchIntervals: () => TAction<Promise<void>>;
    assignInterval: (intervalId: string, date: string) => TAction<Promise<void>>;
    setIntervals: (intervals: TPeriod[]) => TSetIntervals;
};

export type TInterval = {
    day_of_week: number;
    default: number;
    district_id: number;
    express_interval: string;
    id: number;
    is_express: number;
    label: string;
    minutes_for_disable: number;
    price: string;
    shift_closing_to_end: number;
    time_end: string;
    time_start: string;
};

export type TPeriod = {
    date: string;
    label: string;
    intervals: TInterval[];
};

type TAssign = {
    cart: string;
    date: string;
    periodId: string;
};

type TFetchIntervalParams = {
    longitude: string;
    latitude: string;
    addressUuid?: string;
};

const errorHandler = (error: any, defaultErrorMessage: string) => {
    if (Axios.isCancel(error)) {
        return;
    }
    if (error.response && error.response.status !== 404) {
        const errorMessage = error?.message
            ? `${defaultErrorMessage}. ${error?.message}`
            : defaultErrorMessage;
        message.error(errorMessage);
    }
};

const tMap = translateMap.errors;

export const IntervalActions: IntervalActionsType = {
    fetchIntervals() {
        return async (dispatch, getState, { api, httpClientServices }) => {
            const { selectedAddress } = getState().addresses;

            if (selectedAddress) {
                dispatch(OrderActions.setNetworkStatus('intervals', NetworkStatus.loading));
                const params: TFetchIntervalParams = {
                    longitude: selectedAddress.longitude,
                    latitude: selectedAddress.latitude,
                };

                if (selectedAddress.uuid) {
                    params['addressUuid'] = selectedAddress.uuid;
                }

                await httpClientServices
                    .getClient()
                    .get<{ data: TPeriod[]; success: boolean }>(api.getIntervalsByCoords, {
                        params,
                    })
                    .then(({ data }) => {
                        if (data.success) {
                            dispatch(this.setIntervals(data.data));
                        } else {
                            throw Error;
                        }
                    })
                    .catch(e => errorHandler(e, 'Failed to fetch intervals'))
                    .finally(() => {
                        dispatch(OrderActions.setNetworkStatus('intervals', NetworkStatus.ready));
                    });
            }
        };
    },
    assignInterval(intervalId, date) {
        return async (dispatch, getState, { httpClientServices, services }) => {
            const { info } = getState().cart;
            const cart = info?.cart ?? localStorage.getItem('cartId');
            if (cart) {
                const data: TAssign = {
                    cart,
                    date,
                    periodId: intervalId,
                };
                await httpClientServices
                    .getClient('cart')
                    .post(services.cart.assignInterval, data)
                    .then(() => {
                        batch(() => {
                            dispatch(CartActions.calculate());
                            dispatch(OrderActions.setReadyField(CheckoutFields.interval, true));
                        });
                    })
                    .catch(e => {
                        dispatch(OrderActions.setReadyField(CheckoutFields.interval, false));
                        errorHandler(e, t(tMap.assignInterval));
                    });
            }

            return;
        };
    },
    setIntervals(intervals) {
        return {
            type: Types.SET_INTERVALS,
            payload: intervals,
        };
    },
};
