const cartModal = {
    title: {
        success: 'Заказ оформлен',
        noSuccess: 'Заказ не оформлен!',
    },
    subTitle: {
        success: 'Ожидайте доставку в указанное время',
        noSuccess: 'Повторите заказ или укажите другой способ оплаты',
    },
    btnClose: 'Понятно',
};

export default cartModal;
