const ordersHistory = {
    title: 'История заказов',
    btn: {
        more: 'Подробнее',
        repeat: 'Повторить',
        toCatalog: 'В каталог',
        pay: 'Оплатить',
    },
    error: 'К сожалению, данный заказ повторить невозможно',
    noOrders: 'Вы еще не сделали ни одного заказа :(',
    cancelOrder: {
        title: 'Отмена заказа',
        details: {
            orderNumber: 'Заказ №',
            shippingAddress: 'Адрес доставки',
            shippingDate: 'Дата и время доставки',
        },
        leaveComment: 'Оставить свой комментарий',
        selectReasonAndNote: 'Выберите причину отмены заказа или оставьте комментарий',
        btn: {
            back: 'Назад',
            cancel: 'Отменить заказ',
        },
    },
    orderStatus: {
        new: 'Новый',
        completed: 'Завершён',
        approved: 'Подтверждён',
        cancelled: 'Отменён',
        inProcess: 'В обработке',
        undelivered: 'Не доставлен',
        requiresConfirmation: 'На подтверждении',
    },
};

export default ordersHistory;
