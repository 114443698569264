class StorageHelper {
    static defaultExceptions = [
        'language',
        'timestampInitialVisit',
        'timestampDownloadAppModalClosed',
    ];

    static clearLocalStorage = () => {
        Object.keys(localStorage).forEach(key => {
            localStorage.removeItem(key);
        });
    };

    static clearLocalStorageWithExceptions = (exceptions: string[] = []) => {
        exceptions = [...exceptions, ...StorageHelper.defaultExceptions];
        Object.keys(localStorage).forEach(key => {
            if (!exceptions.includes(key)) {
                localStorage.removeItem(key);
            }
        });
    };
}

export default StorageHelper;
