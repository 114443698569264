import { combineReducers } from 'redux';

import addresses from './addresses';
import addressPredictions from './addressPredictions';
import aquamarket from './aquamarket';
import bannersDeeplink from './bannersDeeplink';
import bonusPoints from './bonusPoints';
import cart from './cart';
import catalog from './catalog';
import company from './company';
import history from './history';
import layout from './layout';
import order from './order';
import returnedBottles from './returnedBottles';
import user from './user';

const rootReducer = combineReducers({
    catalog,
    cart,
    returnedBottles,
    company,
    user,
    addresses,
    order,
    history,
    layout,
    bannersDeeplink,
    addressPredictions,
    bonusPoints,
    aquamarket,
});

export default rootReducer;

export type AppStateType = ReturnType<typeof rootReducer>;
