const pointsModal = {
    title: 'Бонусные баллы',
    rulesTitle: 'Правила использования',
    historyTitle: 'История начислений',
    login: 'Войдите или зарегистрируйтесь, чтобы получать баллы',
    btnAuth: 'Вход / Регистрация',
    marketing: {
        title: '1 балл = 1 рубль',
        subtitle: 'Как получить бонусы?',
        text:
            'Рядом с ценой каждого товара указано количество баллов, которое вы получите при покупке этого товара. Бонусы начисляются после успешной доставки заказа (за отменённые заказы и за заказы, полностью или частично оплаченные баллами, бонусы не начисляются). Как потратить бонусы? Оплачивайте покупки баллами при оформлении заказа через приложение.',
    },
    download: {
        start: 'Скачайте приложение',
        end: 'чтобы использовать бонусы для оплаты',
    },
    btnDownload: 'Скачать приложение',
};

export default pointsModal;
