const product = {
    title: '',
    count: 'Sayı',
    totalPay: ' Ümumi ödəniş: ',
    addButton: ' əlavə et',
    addedButton: 'Əlavə edildi',
    price: 'Qiymət: ',
    promotion: 'Malların sayı 1-dən çox olarsa qiymətdə endirim ola bilər',
    maxCountMessage: 'Cari məhsulun maksimum miqdarına çatdı',
    ct: 'əd.',
};

export default product;
