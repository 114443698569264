import NetworkStatus from '../../utils/enums/NetworkStatus';
import { TUser, Types, TUserData, TUserAnonData } from '../actions/user';

export declare type UserState = {
    data: null | TUserAnonData | TUserData;
    networkStatus: NetworkStatus;
};

const initState: UserState = {
    data: null,
    networkStatus: NetworkStatus.pending,
};

const user = (state = initState, action: TUser): UserState => {
    let newState;
    switch (action.type) {
        case Types.SET_USER:
            newState = { ...state, data: action.payload };
            break;
        case Types.SET_NETWORK_STATUS:
            newState = { ...state, networkStatus: action.payload };
            break;
    }
    return (newState as any) || state;
};

export default user;
