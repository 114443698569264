const cartDrawer = {
    cart: {
        title: 'Cart',
        relatedTitle: 'With this product usually take',
        empty: 'Cart is empty :(',
        backButton: 'Back',
    },
    auth: {
        title: 'Authorization',
        description: 'Login or register to place orders, receive bonuses and more',
        btnAuth: 'Login / Registration',
        btnAnon: 'Order without registration',
    },
    login: {
        title: 'Login / Registration',
    },
    anon: {
        title: 'Enter your number',
        description:
            'it is necessary for operator to confirm the order, and for courier to contact you in case of need',
        btnSend: 'Send',
        orAuth: {
            or: 'or',
            auth: 'Sign up',
            description: 'to see you orders history, earn points and repeat orders in a few clicks',
        },
    },
    back: 'Back',
};

export default cartDrawer;
