import NetworkStatus from '../../utils/enums/NetworkStatus';
import { TAddressesActions, Types, TAddress } from '../actions/addresses';

export declare type AddressesState = {
    addresses: TAddress[] | null;
    selectedAddress: TAddress | null;
    networkStatus: NetworkStatus;
};

const initState: AddressesState = {
    addresses: null,
    selectedAddress: null,
    networkStatus: NetworkStatus.pending,
};

const addresses = (state = initState, action: TAddressesActions): AddressesState => {
    let newState;
    switch (action.type) {
        case Types.SET_ADDRESS:
            newState = { ...state, addresses: action.payload };
            break;
        case Types.SET_SELECTED_ADDRESS:
            newState = { ...state, selectedAddress: action.payload };
            break;
        case Types.SET_NETWORK_STATUS:
            newState = { ...state, networkStatus: action.payload };
            break;
    }

    return (newState as any) || state;
};

export default addresses;
