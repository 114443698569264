const cart = {
    product: {
        title: 'sepet',
        relatedTitle: 'bu ürün ile genellikle almak',
        cartEmpty: 'Sepette boş :(',
        backToCatalog: 'Alışverişe doğru',
        next: 'Sonraki',
        autoProduct: 'Otomatik olarak eklendi',
        returnedBottles: {
            title: 'Dönüş kabı',
            buttons: {
                addBottles: 'Ambalaj ekle',
                skipBottles: 'Ambalaj olmadan devam',
            },
        },
        deletedProduct: 'öğe kaldırıldı',
        restoreProductBtn: 'Geri Yükle',
    },
    order: {
        title: 'teslim',
        payment: 'Çevrimiçi ödeme',
        addAddress: {
            title: 'Teslimat adresi',
            subtitle: 'Böylece kurye sizi bulabilir',
            btnOpen: 'Adres Ekle',
            loading: 'Adreslerin yüklenmesi',
            back: 'geri',
            noFoundAdress: 'Doğru adresi bulamadım',
            manuallyLocation: 'Siparişi teslim etmek istediğiniz haritadaki tam yeri belirtin',
            mainLocation: 'yerellik: ',
            secondaryLocation: 'Sokak: ',
            coordinates: 'koordinatlar: ',
            btnConfirm: 'adresi Sil',
            btnNext: 'Sonraki',
            fullAddress: 'Tam adresi belirtin',
            street: 'Sokak',
            house: 'ev',
            entrance: 'giriş',
            floor: 'kat',
            room: 'Daire/Ofis',
            name: 'yer adı',
            noRoomNumber: 'Daire numarası olmadan',
            comment: 'yorum',
            errorLocationSearch: 'Maalesef adresiniz bulunamadı',
            placeholder: 'Şehri, sokağı ve evi girin',
            notFound: 'Hiçbir şey bulunamadı',
        },
        addressField: {
            title: 'teslimat adresi',
            noAddresses: 'adres yok',
            addAddress: 'adresi seçin',
            changeAddress: 'adresi değiştir',
            city: 'G.',
            street: '',
            house: '',
            entrance: 'giriş.',
            floor: 'kat.',
            room: 'daire',
        },
        deliveryTime: {
            title: 'teslim süresi',
            loading: 'Aralıkların yüklenmesi',
            noTimeForAdress: 'Belirtilen adres için kullanılabilir zaman yok',
            addressNotSelected: 'Teslimat adresi seçilmedi',
        },
        bonusField: {
            title: 'Bonus puanları',
            useBonuses: 'Puanları kullan',
            notSufficientBonuses: 'Sipariş için ödemek için yeterli puan yok',
            firstOrder: 'Bonus puan biriktirmeye başlamak için sipariş verin.',
            payExtra: 'Ekstra ödemek gerekecek {{value}}',
            more: 'Etraflıca',
        },
        phoneNumber: 'Telefon numarası',
        enterPhoneNumber: 'Lütfen telefon numarasını belirtin',
        notValidPhoneNumber: 'Lütfen doğru telefon numarasını belirtin',
        orderCost: 'Sipariş ve maliyet',
        orderSum: 'Sipariş miktarı: ',
        deliveryCost: 'Teslim: ',
        paymentMethod: 'ödeme yöntemi',
        paymentLoading: 'Ödeme yöntemlerini yükleme',
        errorPaymentLoading: 'Ödeme türü alma hatası',
        promocodeTitle: 'Promosyon kodunu girin',
        apply: 'uygulamak',
        promocode: 'promosyon kodu',
        comment: 'Sipariş üzerine yorum',
        pc: ' adet',
    },
    footer: {
        returnedBottles: 'şişe değişimi',
        helpMessageOk: 'Tamam, anladım',
        infoMessage: 'Geri dönüş kabı bir su şişesinden daha büyük olamaz',
        sum: 'toplam: ',
        bonuses: ' puan',
        shortBonuses: ' b.',
        delivery: 'teslimat: ',
        сheckout: 'sipariş ver',
        total: 'ödenecek: ',
    },
};

export default cart;
