const cartDrawer = {
    cart: {
        title: 'Səbət',
        relatedTitle: 'Bu məhsulla adətən alınır',
        empty: 'Səbət boşdur :(',
        backButton: ' Geri',
    },
    auth: {
        title: 'Şəxsi kabinetə giriş',
        description:
            'Sifariş vermək, bonuslar almaq və s. üçün daxil olun və ya qeydiyyatdan keçin',
        btnAuth: 'Giriş/Qeydiyyat',
        btnAnon: 'Qeydiyyat olmadan sifariş',
    },
    login: {
        title: 'Giriş/Qeydiyyat',
    },
    anon: {
        title: 'Telefon nömrənizi daxil edin',
        description:
            'Operatorun sifarişi təsdiq edə bilməsi və lazım olduqda kuryerin sizinlə əlaqə saxlaması üçün',
        btnSend: 'Göndər',
        orAuth: {
            or: 'və ya',
            auth: 'Şəxsi kabinetə girin',
            description:
                'Alış-veriş tarixçənizi görmək, xal toplamaq və bir neçə klikə sifarişləri təkrarlamaq üçün',
        },
    },
    back: 'Geri',
};

export default cartDrawer;
