import { TLayoutActions, LayoutActionTypes } from '../actions/layout';

export type TLayoutState = {
    header: { hidden: boolean };
    footer: { hidden: boolean };
    body: { noScroll: boolean };
};

const initState: TLayoutState = {
    header: { hidden: false },
    footer: { hidden: false },
    body: { noScroll: false },
};

const layout = (state = initState, action: TLayoutActions): TLayoutState => {
    switch (action.type) {
        case LayoutActionTypes.LAYOUT_SET_HEADER_HIDDEN:
            return { ...state, header: { hidden: action.payload.hidden } };
        case LayoutActionTypes.LAYOUT_SET_FOOTER_HIDDEN:
            return { ...state, footer: { hidden: action.payload.hidden } };
        case LayoutActionTypes.LAYOUT_SET_BODY_NO_SCROLL:
            return { ...state, body: { noScroll: action.payload.noScroll } };
        default:
            return state;
    }
};

export default layout;
