const layout = {
    header: {
        company: 'Şirket',
        user: 'Kullanıcı',
        subtitle: 'Şehirde teslimat',
        buttonBack: 'geri',
        buttonCheckout: 'sipariş ver',
    },
    mobileMenu: {
        company: 'Şirket Ayarları',
        user: 'profil',
    },
    footer: {
        contractOffer: 'Teklif sözleşmesi',
        privacyPolicy: 'Gizlilik Politikası',
        userAgreement: 'Kullanım Şartları',
        partnerWebsite: 'İş Ortağı Sitesi App Solution',
    },
    buttons: {
        logout: 'Çıkmak',
    },
};

export default layout;
