import React from 'react';

import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';

const AbsoluteFilledLoader: React.FC<SpinProps> = ({ children, style, ...other }) => {
    return (
        <Spin
            delay={200}
            style={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'white',
                maxHeight: 'unset',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...style,
            }}
            {...other}
        >
            {children}
        </Spin>
    );
};

export default AbsoluteFilledLoader;
