import * as Sentry from '@sentry/react';

export type EventData = Record<string | number, unknown>;
export type EventOptions = {
    category?: string;
    type?: string;
    message?: string;
    data?: EventData;
    sendEvents?: boolean;
};

export interface Logger {
    info(data: EventOptions): void;
    warning(data: EventOptions): void;
    error(data: EventOptions): void;
    captureException(error?: Error): void;
    captureMessage(message?: string): void;
}

type SentryEventData = EventOptions & Sentry.Breadcrumb;

export default class SentryLogger implements Logger {
    private createBreadcrumb(data: SentryEventData) {
        const { sendEvents, ...breadcrumb } = data;

        this.addBreadcrumb({ ...breadcrumb });

        if (sendEvents) {
            return this.captureMessage(breadcrumb.message);
        }
    }

    private addBreadcrumb(breadcrumbData: Sentry.Breadcrumb) {
        Sentry.addBreadcrumb(breadcrumbData);
    }

    info(data: EventOptions) {
        this.createBreadcrumb({
            type: 'info',
            category: 'info',
            ...data,
            level: 'info',
        });
    }

    warning(data: EventOptions) {
        this.createBreadcrumb({
            type: 'info',
            category: 'warning',
            ...data,
            level: 'warning',
        });
    }

    error(data: EventOptions) {
        this.createBreadcrumb({
            type: 'error',
            category: 'error',
            ...data,
            level: 'error',
        });
    }

    captureException(error: Error) {
        if (error) {
            Sentry.captureException(error);
        }
    }

    captureMessage(message?: string) {
        if (message) {
            Sentry.captureMessage(message);
        }
    }
}
