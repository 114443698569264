import { LocalizationContextValues } from './Localization';

const localizationConfig: LocalizationContextValues = {
    countries: {
        AZ: {
            currency: 'RUB',
        },
        AM: {
            currency: 'RUB',
        },
        BY: {
            currency: 'RUB',
        },
        KZ: {
            currency: 'RUB',
        },
        KG: {
            currency: 'RUB',
        },
        MD: {
            currency: 'RUB',
        },
        RU: {
            currency: 'RUB',
        },
        TJ: {
            currency: 'RUB',
        },
        UZ: {
            currency: 'RUB',
        },
        BE: {
            currency: 'EUR',
        },
        GR: {
            currency: 'EUR',
        },
        LT: {
            currency: 'EUR',
        },
        PT: {
            currency: 'EUR',
        },
        ES: {
            currency: 'EUR',
        },
        LU: {
            currency: 'EUR',
        },
        FR: {
            currency: 'EUR',
        },
        SI: {
            currency: 'EUR',
        },
        DK: {
            currency: 'EUR',
        },
        MT: {
            currency: 'EUR',
        },
        SK: {
            currency: 'EUR',
        },
        DE: {
            currency: 'EUR',
        },
        IT: {
            currency: 'EUR',
        },
        NL: {
            currency: 'EUR',
        },
        FI: {
            currency: 'EUR',
        },
        EE: {
            currency: 'EUR',
        },
        CY: {
            currency: 'EUR',
        },
        AT: {
            currency: 'EUR',
        },
        IE: {
            currency: 'EUR',
        },
        LV: {
            currency: 'EUR',
        },
    },
    defaultCurrency: 'USD',
};

export default localizationConfig;
