import { DeeplinkTypes } from '../../entities/BannersEntity';

export const Types = {
    SET_COMPONENT_SHOW: 'BANNERS@SET:COMPONENT:SHOW',
};

type TBannersDeeplinkType = {
    setComponentShow: (name: DeeplinkTypes | null) => TComponentAction;
};

export type TComponentAction = {
    type: typeof Types.SET_COMPONENT_SHOW;
    payload: DeeplinkTypes | null;
};

export type TBannersDeeplinkActions = TComponentAction;

export const BannersDeeplinkActions: TBannersDeeplinkType = {
    setComponentShow(name) {
        return {
            type: Types.SET_COMPONENT_SHOW,
            payload: name,
        };
    },
};
