import { createContext } from 'react';

import { Alpha2Code } from 'i18n-iso-countries';
import Currency from '../CurrencyHelper';

export type Currency = 'RUB' | 'USD' | 'EUR';

export type LocalizationContextValues = {
    countries: {
        [key in Alpha2Code]?: {
            currency: Currency;
        };
    };
    defaultCurrency: Currency;
};

const Localization = createContext<LocalizationContextValues>({
    countries: {},
    defaultCurrency: 'USD',
});

export default Localization;
