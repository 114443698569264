import { message } from 'antd';
import Axios, { AxiosRequestConfig } from 'axios';

import { batch } from 'react-redux';

import {
    GeocodingAddress,
    TFetchResult,
    TPrediction,
    TPredictionRequest,
    TPredictionResult,
} from '../../types';
import { getAppConfig } from '../../utils/appConfig';
import NetworkStatus from '../../utils/enums/NetworkStatus';
import URLHelper from '../../utils/URLHelper';
import { getYMapsToken } from '../../utils/yandexMapsToken';
import { TAction } from '../store';

export const Types = {
    SET_PREDICTIONS: 'PREDICTIONS@SET:PREDICTIONS',
    SET_NETWORK_STATUS: 'PREDICTIONS@SET:NETWORK:STATUS',
    SET_YA_PREDICTIONS: 'PREDICTIONS@SET:YA:PREDICTIONS',
    SET_YA_PREDICTION_GEOCODE: 'PREDICTIONS@SET:YA:ADDRESS:BY:COORDS',
    SET_IS_YA_AUTOCOMPLETE_ENABLED: 'PREDICTIONS@SET:IS:YA:AUTOCOMPLETE:ENABLED',
};

export type TYaPrediction = {
    title: {
        text: string;
        hl: any;
    };
    subtitle: {
        text: string;
        hl: any;
    };
};

type TSetAddressPredictions = {
    type: typeof Types.SET_PREDICTIONS;
    payload: TPrediction[];
};

type TSetYaPredictions = {
    type: typeof Types.SET_YA_PREDICTIONS;
    payload: TYaPrediction[];
};

type TSetNetworkStatus = {
    type: typeof Types.SET_NETWORK_STATUS;
    payload: NetworkStatus;
};

type TSetYaPredictionGeocode = {
    type: typeof Types.SET_YA_PREDICTION_GEOCODE;
    payload: GeocodingAddress | null;
};

type TSetIsYaAutocompleteEnabled = {
    type: typeof Types.SET_IS_YA_AUTOCOMPLETE_ENABLED;
    payload: boolean;
};

export type AddressPredictionsActionsType = {
    getAddressPredictions: (address: string) => TAction<Promise<void>>;
    getYandexAddressPredictions: (address: string) => TAction<Promise<void>>;
    getYandexPredictionGeocode: (address: string) => TAction<Promise<GeocodingAddress | null>>;
    setYandexAddressPredictions: (yaPredictions: TYaPrediction[]) => TSetYaPredictions;
    setAddressPredictions: (predictions: TPrediction[]) => TSetAddressPredictions;
    setYandexPredictionGeocode: (address: GeocodingAddress | null) => TSetYaPredictionGeocode;
    setIsYaAutocompleteEnabled: (value: boolean) => TSetIsYaAutocompleteEnabled;
    setNetworkStatus: (status: NetworkStatus) => TSetNetworkStatus;
};

export type TAddressPredictionsActions = TSetAddressPredictions;

export const AddressPredictionsActions: AddressPredictionsActionsType = {
    getAddressPredictions(address) {
        return async (dispatch, getState) => {
            const fetch = () => {
                const { center_latitude: lat, center_longitude: lon } = getState().company.info
                    ?.defaultCity || {
                    center_latitude: '',
                    center_longitude: '',
                };
                const type: TPredictionRequest = 'geocode';
                const { url: origin } = getAppConfig();
                const path = 'place/autocomplete/search';

                const url = new URL(path + '?', origin).toString();

                const searchParams = new URLSearchParams({
                    address,
                    type,
                    lat,
                    lon,
                }).toString();

                const config: AxiosRequestConfig = {
                    timeout: 30000,
                    cancelToken: Axios.CancelToken.source().token,
                };
                return Axios.get<TFetchResult<TPredictionResult>>(url + searchParams, config);
            };
            dispatch(this.setNetworkStatus(NetworkStatus.loading));

            try {
                const response = await fetch();
                dispatch(this.setAddressPredictions(response.data.data.predictions));
            } catch (e) {
                if (Axios.isCancel(e)) return;
                else if (e instanceof Error) {
                    message.error(e.message);
                }
            } finally {
                dispatch(this.setNetworkStatus(NetworkStatus.ready));
            }
        };
    },
    getYandexPredictionGeocode(address) {
        return async dispatch => {
            const apiKey = getYMapsToken();
            const path = URLHelper.buildUrl(
                '/1.x?apikey={apikey}&geocode={geocode}&format={format}',
                {
                    apikey: apiKey || '',
                    geocode: address,
                    format: 'json',
                },
            );
            const url = `https://geocode-maps.yandex.ru${path}`;
            try {
                const { data } = await Axios.get(url);
                const coords = data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(
                    ' ',
                );
                const geo: { kind: string; name: string }[] =
                    data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty
                        .GeocoderMetaData.Address.Components;
                const address: GeocodingAddress = {
                    address: {
                        house_number: geo.find(item => item.kind === 'house')?.name,
                        road: geo.find(item => item.kind === 'street')?.name,
                        city: geo.find(item => item.kind === 'locality')?.name,
                        county: geo.find(item => item.kind === 'area')?.name,
                    },
                    lon: parseFloat(coords[0]),
                    lat: parseFloat(coords[1]),
                };
                dispatch(this.setYandexPredictionGeocode(address));

                return address;
            } catch (e) {
                dispatch(this.setIsYaAutocompleteEnabled(false));
            }

            return null;
        };
    },

    getYandexAddressPredictions(address) {
        return async dispatch => {
            const apiKey = getAppConfig().yandexSuggestToken;
            const path = URLHelper.buildUrl('/v1/suggest?apikey={apikey}&text={text}', {
                apikey: apiKey || '',
                text: address,
            });
            const url = `https://suggest-maps.yandex.ru${path}`;
            dispatch(this.setNetworkStatus(NetworkStatus.loading));
            await Axios.get(url)
                .then(({ data }) => {
                    const suggestions = data.results.map((item: any) => ({
                        title: item.title,
                        subtitle: item.subtitle,
                    }));
                    batch(() => {
                        dispatch(this.setYandexAddressPredictions(suggestions));
                        dispatch(this.setNetworkStatus(NetworkStatus.ready));
                    });
                })
                .catch(() => {
                    dispatch(this.setIsYaAutocompleteEnabled(false));
                });
        };
    },

    setAddressPredictions(predictions) {
        return {
            type: Types.SET_PREDICTIONS,
            payload: predictions,
        };
    },
    setYandexAddressPredictions(yaPredictions) {
        return {
            type: Types.SET_YA_PREDICTIONS,
            payload: yaPredictions,
        };
    },
    setYandexPredictionGeocode(address) {
        return {
            type: Types.SET_YA_PREDICTION_GEOCODE,
            payload: address,
        };
    },
    setIsYaAutocompleteEnabled(value) {
        return {
            type: Types.SET_IS_YA_AUTOCOMPLETE_ENABLED,
            payload: value,
        };
    },
    setNetworkStatus(status) {
        return {
            type: Types.SET_NETWORK_STATUS,
            payload: status,
        };
    },
};
