const pointsModal = {
    title: 'Bonus xalları',
    rulesTitle: 'İstifadə qaydaları',
    historyTitle: 'Hesablama tarixi',
    login: 'Bonus almaq üçün daxil olun və ya qeydiyyatdan keçin',
    btnAuth: 'Giriş / Qeydiyyat',
    marketing: {
        title: '1 балл = 1 manat',
        subtitle: ' Bonusları necə əldə etmək olar?',
        text:
            'Hər bir məhsulun qiymətinin yanında həmin məhsulu alarkən qazanacağınız bonus xallarının sayı göstərilir. Bonuslar, sifarişin uğurla çatdırılmasından sonra verilir.(ləğv edilmiş sifarişlər və xallarla tam və ya qismən ödənilən sifarişlər üçün bonus verilmir Bonusları necə xərcləmək olar? Mobil proqram vasitəsilə sifariş verərkən, bonus xalları ilə ödəniş edin.',
    },
    download: {
        start: 'Proqramı yükləyin',
        end: 'ödəniş üçün bonuslardan istifadə etmək üçün',
    },
    btnDownload: 'Proqramı yükləyin',
};

export default pointsModal;
