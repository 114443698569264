import { useMemo, useCallback } from 'react';

const useDocumentLanguage = () => {
    const htmlElement = useMemo(() => document.querySelector('html'), []);

    const updateDocumentLocale = useCallback(
        (locale: string) => {
            if (htmlElement) htmlElement.lang = locale;
        },
        [htmlElement],
    );

    return {
        updateDocumentLocale,
    };
};

export default useDocumentLanguage;
